import React from 'react';
import Home from './pages/Home.js';

function App() {
  return <Home title="Yorick Devleeschouwer" />;
}

export default App


//react router